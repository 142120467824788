import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useForm } from 'react-hook-form';
import FeedbackService from '../service/FeedbackService';
import NotifyController from '../utilities/Toast';

const CreateTemplateModal = ({ onClose }: { onClose: Function }) => {
    const { register, handleSubmit, setValue } = useForm<{
        group: string;
        type: string;
        prompt: string;
        avatarUrl: string;
        imageUrl: string;
        is_published: boolean;
        is_reward: number;
    }>({
        defaultValues: {
            group: '',
            type: '',
            prompt: '',
            avatarUrl: '',
            imageUrl: '',
            is_published: false,
            is_reward: 0,
        }
    });

    const onSubmit = (formData: any) => {
        if (formData.group === '' || formData.avatarUrl === '' || formData.imageUrl === '') {
            NotifyController.error('Please fill all fields');
            return;
        }
        const data = {
            group: formData.group,
            type: formData.type,
            avatar_url: formData.avatarUrl,
            image_url: formData.imageUrl,
            prompt: formData.prompt,
            is_published: formData.is_published,
            is_reward: +(formData.is_reward ?? "0"),
        };

        FeedbackService.getInstance()
            .addTemplateV2(data)
            .then(() => {
                NotifyController.success('Create success');
                onClose();
            })
            .catch((error) => {
                NotifyController.error(error?.message);
                console.log(error);
            });
    };

    const myUploader = async (event: any, type: string) => {
        try {
            const file = event.target.files[0];
            // const formData = new FormData();
            // formData.append('file', file);

            const res = await FeedbackService.getInstance().getSignedUrl(file.name, file.type);
            const signedRequest = res.data.signedRequest;

            // Perform a PUT request to the signed URL
            const response = await fetch(signedRequest, {
                method: 'PUT',
                body: file,
                headers: {
                    'Content-Type': file.type
                }
            });

            if (response.ok) {
                NotifyController.success('Image uploaded successfully');
                if (type === 'avatar') {
                    setValue('avatarUrl', res.data.url);
                } else if (type === 'image') {
                    setValue('imageUrl', res.data.url);
                }
            } else {
                console.error('Failed to upload image');
            }
        } catch (error: any) {
            console.error('Error uploading image:', error);
            NotifyController.error(error?.message);
        }
    };

    return (
        <Dialog header="Create Template" visible={true} style={{ width: '50vw' }} onHide={() => onClose(false)}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/* Form */}
                <div className="p-fluid">
                    <div className="p-field mb-3">
                        <label htmlFor="group">Group</label>
                        <InputText id="group" {...register('group')} />
                    </div>
                    <div className="p-field mb-3">
                        <label htmlFor="type">Type</label>
                        <InputText id="type" {...register('type')} />
                    </div>
                    <div className="p-field mb-3">
                        <label htmlFor="avatarUrl">Avatar URL</label>
                        <input id="avatarUrl" type="file" name="avatar" accept="image/*" onChange={(e) => myUploader(e, 'avatar')} />
                    </div>
                    <div className="p-field mb-3">
                        <label htmlFor="imageUrl">Image URL</label>
                        <input id="imageUrl" type="file" name="image" accept="image/*" onChange={(e) => myUploader(e, 'image')} />
                    </div>
                    <div className="p-field mb-3">
                        <label htmlFor="prompt">Prompt</label>
                        <InputTextarea id="prompt" {...register('prompt')} />
                    </div>
                    <div className="p-field mb-3 flex items-center">
                        <label htmlFor="is_published">Status</label>
                        <input type="checkbox" id='is_published' onChange={(event) => setValue('is_published', event.target.checked)} />
                    </div>
                    <div className="p-field mb-3 flex items-center gap-x-4">
                        <label htmlFor="is_reward">Reward Ads</label>
                        <select id='is_reward' {...register('is_reward')} className='ml-4 px-2 py-1' style={{ width: '50px' }}>
                            <option value={0}>0</option>
                            <option value={1}>1</option>
                        </select>
                    </div>
                </div>

                {/* Buttons */}
                <div className="p-mt-2">
                    <Button label="Submit" type="submit" className="p-button-success mr-3" />
                    <Button label="Cancel" onClick={() => onClose(false)} className="p-button-secondary p-button-outlined p-ml-2" />
                </div>
            </form>
        </Dialog>
    );
};

export default CreateTemplateModal;
