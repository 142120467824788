import React, { useState } from 'react';
import { Column } from 'primereact/column';
import { CustomDataTable, filterApplyTemplate, filterClearTemplate, tableOptions } from '../components/CustomDatatable';
import NotifyController from '../utilities/Toast';
import FeedbackService from '../service/FeedbackService';
import { Image } from 'primereact/image';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import moment from 'moment';

const Report = () => {
    const navigate = useNavigate();
    const { page } = useParams();

    const [refresh, setRefresh] = useState<boolean>(false)
    const [searchParams] = useSearchParams();
    const [tableOptions, _] = useState<{
        rowPerPage: number;
        filter: any;
        filters?: any;
        page: number;
        order?: string;
        orderType?: 1 | 0 | -1 | undefined | null;
        selectedCol?: string | null;
    }>((searchParams.get('query')
        ? JSON.parse(decodeURIComponent(searchParams.get('query') as string))
        : (sessionStorage.getItem('rp_query')
            ? JSON.parse(sessionStorage.getItem('rp_query') as string) : undefined)));


    const onOptionChange = async (option: tableOptions) => {
        navigate(`?query=${encodeURIComponent(JSON.stringify(option))}`);
        sessionStorage.setItem('rp_query', JSON.stringify(option));

        const feedbacks = await FeedbackService.getInstance().getListFeedback(option.page, option.rowPerPage, 'APP_CHANGECLOTHER_POLICY').catch((error) => {
            NotifyController.error(error?.message)
            console.log(error);
        })
        return { total: feedbacks.data.totalRecord, data: feedbacks.data.items }
    }

    const bodyAssetUrl = (rowData: any) => {
        return (
            <div className="p-3">
                <Image src={rowData.image_url} alt={rowData.image_url} width="120" preview />
            </div>
        );
    };

    const bodyFormattedDateTime = (rowData: any) => {
        const timestamp = rowData.created_ts;
        if (!timestamp) return <span></span>;

        // Convert the timestamp using moment and adjust for GMT+7 timezone
        const formattedDateTime = moment(timestamp)
            .utcOffset(7) // Set offset for GMT+7
            .format('YYYY-MM-DD HH:mm:ss'); // Format the date as 'yyyy-MM-dd HH:mm:ss'

        return <span>{formattedDateTime}</span>;
    };

    const handleDetailButtonClick = async (rowData: any) => {
        try {
            const imageData = await FeedbackService.getInstance().getIdFromUrl(rowData.image_url);
            navigate(`/generated-image/detail/${imageData.data}`);
        } catch (error: any) {
            NotifyController.error(error?.message);
            console.log(error);
        }
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <h5>Feedback</h5>
                    <CustomDataTable
                        onOptionChange={onOptionChange}
                        refresh={refresh}
                        page={parseInt(page ?? '1')}
                        tableOption={tableOptions}
                    >
                        <Column
                            body={(rowData: any) => (
                                <Button onClick={() => handleDetailButtonClick(rowData)}>Detail</Button>
                            )}
                            header="Action"
                            headerStyle={{ width: '6rem', textAlign: 'center' }}
                            style={{ textAlign: 'center' }}
                        />
                        <Column field="created_ts" sortable body={bodyFormattedDateTime} header="Created" headerStyle={{ minWidth: '10rem' }} filter filterClear={filterClearTemplate} filterApply={filterApplyTemplate}></Column>
                        <Column field="content" header="Content" sortable headerStyle={{ minWidth: '10rem' }} filter filterClear={filterClearTemplate} filterApply={filterApplyTemplate}></Column>
                        <Column field="image_url" body={bodyAssetUrl} header="Asset" headerStyle={{ width: 120 }}></Column>
                    </CustomDataTable>

                </div>
            </div>
        </div>
    );
};

export default Report;
