import moment from 'moment';
import { Column } from 'primereact/column';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomDataTable, tableOptions } from '../components/CustomDatatable';
import FeedbackService from '../service/FeedbackService';
import NotifyController from '../utilities/Toast';


const SubscriptionInfo = () => {
    const navigate = useNavigate();
    const { page } = useParams();

    const onOptionChange = async (option: tableOptions) => {
        const feedbacks = await FeedbackService.getInstance().getListOrder(
            option.page,
            option.rowPerPage,
            option.orderType,
            option.order,
            option.filter,
            option.selectedCol
        ).catch((error) => {
            NotifyController.error(error?.message)
            console.log(error);
        })
        return { total: feedbacks.data.totalRecord, data: feedbacks.data.items }
    }

    const bodyFormattedDateTime = (rowData: any) => {
        const timestamp = rowData.created_ts;
        if (!timestamp) return <span></span>;

        // Convert the timestamp using moment and adjust for GMT+7 timezone
        const formattedDateTime = moment(timestamp)
            .utcOffset(7) // Set offset for GMT+7
            .format('YYYY-MM-DD HH:mm:ss'); // Format the date as 'yyyy-MM-dd HH:mm:ss'

        return <span>{formattedDateTime}</span>;
    };

    const handlePageChange = (page: any) => {
        navigate(`/subscription/${page}`)
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <h5>Generated Image</h5>
                    <CustomDataTable
                        onOptionChange={onOptionChange}
                        page={parseInt(page ?? '1')}
                        handlePageChange={handlePageChange}
                    >
                        <Column field="created_ts" sortable body={bodyFormattedDateTime} header="Created" headerStyle={{ minWidth: '10rem' }}></Column>
                        <Column field="store" header="Store" sortable filter headerStyle={{ minWidth: '10rem' }} ></Column>
                        <Column field="status" header="Status" sortable headerStyle={{ minWidth: '10rem' }} ></Column>
                        <Column field="device_token" header="Device ID" sortable headerStyle={{ minWidth: '10rem' }} ></Column>
                        <Column field="product_id" header="Product Id" headerStyle={{ width: 120 }}></Column>
                        <Column field="transaction_id" header="Transaction Id" headerStyle={{ width: 120 }}></Column>
                        <Column field="is_restore" header="Is Restore" sortable headerStyle={{ minWidth: '10rem' }} ></Column>
                        {/* <Column field="reason" header="" headerStyle={{ width: 120 }}></Column> */}
                    </CustomDataTable>

                </div>
            </div>
        </div>
    );
};

export default SubscriptionInfo;
